<template>
  <div class="pc" v-wechat-title="$route.meta.title">
    <van-sticky>
      <navigation :msg="msg">
        <template>
          <div class="deleteqr2" @click="bianjimeth">{{ bianjimsg }}</div>
        </template>
      </navigation>
    </van-sticky>
    <!-- <div class="deleteqr" @click="clickdeleteqr">删除</div> -->
  
    <div @click="$router.push('/mustread')" class="biduclass">
      <img src="../../assets/bidu.png" />
    </div>

    <p v-show="isload" class="load">{{ loadmsg }}</p>
    <!-- <van-search v-model="value" placeholder="请输入搜索关键词" /> -->
    <div
      @click="checkmeth(index, item)"
      v-bind:class="{ beiall: isbeiall, bianji: isbianji }"
      v-for="(item, index) in allmsg"
      :key="index"
    >
      <div class="namo">
        <p>{{ item.qr_name }}</p>
        <p>¥{{ item.num }}元</p>
      </div>
      <div class="centerclass">
        <div id="peoclass">
          <p>{{ item.pay_type == "4" ? "委托方支付" : "候选人支付" }}</p>
          <p>已用人数：</p>
          <p>{{ item.use_count }}</p>
        </div>
        <div class="qrname">
          <p id="qrnameid">{{ item.product_edition }}</p>
        </div>
      </div>
      <div>
        <div>
          <p>二维码状态：</p>
          <p>{{ item.status == "1" ? "开启" : "关闭" }}</p>
        </div>
        <div>
          <button @click.stop="download(item)" class="qrbu">预览二维码</button>
          <button @click.stop="shbumeth(item, index)" class="shbu">设置</button>
        </div>
      </div>
      <div v-show="isbianji == true" class="check">
        <div v-show="checkarray[index] == item.id"></div>
      </div>
    </div>
    <!-- // 加载更多按钮 -->
    <loadmore ref="loadmore" :withoutmsg="withoutmsg" :open="open"></loadmore>

    <div v-show="isbianji == true" class="botqr" id="selength">
      <!-- <div v-show="selectlength > 0" class="botqr" id="selength"> -->
      <div>
        已选择：<span>{{ selectlength }}</span
        >个
      </div>
      <div class="sede">
        <div @click.stop="allqrmeth">{{ checkmsg }}</div>
        <div class="deleteqr" @click.stop="clickdeleteqr">删除</div>
      </div>
    </div>
    <div class="botqr">
      <div>
        二维码共计：<span>{{ count }}</span
        >个
      </div>
      <button @click.stop="createqrcode" id="adqr">创建二维码</button>
    </div>
    <div class="bodi"></div>
    <van-action-sheet v-model="show" :actions="actions" @select="onSelect" />
  </div>
</template>
<script>
import { Empty } from "vant";
import loadmore from "../../components/loadmore/index";
export default {
  components: {
    loadmore,
  },
  data() {
    return {
      msg: "二维码管理",
      value: "",
      allmsg: "", //所有数据
      loadmsg: "努力加载中...",
      isload: true, //是否显示加载中
      count: "", //二维码总数
      show: false,
      actions: [
        { name: "启用", type: "1" },
        { name: "禁用", type: "0" },
      ], //1-开启 0-关闭
      sename: "", // 修改提示语
      checkarray: [], // 被选中的数组
      selectlength: 0, //选中的二维码
      checkes: "", //操作数据
      checkmsg: "全选",
      open: false,
      withoutmsg: false,
      isbeiall: true,
      isbianji: false,
      bianjimsg: "管理",
    };
  },
  mounted() {
    this.moreload = this.moreload(this);
  },
  destroyed() {
    this.checkes = null;
  },
  created() {
    this.checkes = this.checkmethes(this);
    this.selectallmsg();
  },
  methods: {
    bianjimeth() {
      if (this.bianjimsg == "管理") {
        this.bianjimsg = "完成";
        this.isbianji = true;
      } else if (this.bianjimsg == "完成") {
        this.bianjimsg = "管理";
        this.isbianji = false;
      }
    },

    //点击加载更多数据
    clickload() {
      this.moreload();
    },

    // 加载更多数据
    moreload(vue) {
      var page = 1,
        allmsg;
      /**
       * 加载更多数据
       * */
      function loadmoremsg() {
        vue.open = true;
        this.$refs.loadmore
          .httpload("/firm/v1/Background/qrCodeList", {
            reqType: "Background",
            page: ++page,
          })
          .then((res) => {
            let theallmsg = JSON.parse(res.data).data.qr_code_list;
            if (theallmsg.length < 1) {
              vue.withoutmsg = false;
            } else {
              vue.withoutmsg = true;
              for (let i = 0; i < theallmsg.length; i++) {
                vue.allmsg.push(theallmsg[i]);
              }
            }
            vue.open = false;
          });
      }
      return loadmoremsg;
    },

    // 查询二维码数据
    selectallmsg() {
      this.$http
        .post("/firm/v1/Background/qrCodeList", {
          reqType: "Background",
          page: 1,
        })
        .then((res) => {
          let msg = JSON.parse(res.data);

          this.count = msg.data.count; //二维码共计
          if (this.count > 20) {
            this.withoutmsg = true;
          }
          this.allmsg = msg.data.qr_code_list; //所有数据
          for (let i = 0; i < this.allmsg.length; i++) {
            let set_id = JSON.parse(this.allmsg[i].set_id);
            this.allmsg[i].set_id = set_id;
          }

          if (this.allmsg.length < 1) {
            this.loadmsg = "暂无数据";
          } else {
            this.isload = false;
          }
        });
    },

    //删除二维码
    clickdeleteqr() {
      this.checkes.deleteqr();
    },

    // 选中二维码
    checkmeth(index, item) {
      if (!this.isbianji) return;
      this.checkes.selectdiv(index, item);
    },

    //全选二维码
    allqrmeth() {
      this.checkes.selectalldiv();
    },

    //二维码操作
    checkmethes(that, oldarraylength) {
      var upqrarray = []; //提交数据（数组）
      var oldarraylength = oldarraylength; //原始数组长度
      that.checkarray.length = 20;
      var isAll = false; //是否是全选
      /**
       * 显示选中的按钮
       */
      function selectdiv(index, item) {
        addpage();
        if (that.checkarray[index] != item.id) {
          that.checkarray.splice(index, 1, item.id);
          that.selectlength++;
        } else {
          that.checkarray.splice(index, 1, null);
          that.selectlength--;
        }

        isallselectmeth();
      }

      /**
       * 所有选中的数据（去除无关数据），用于提交或删除
       */
      function upqr() {
        for (let i = 0; i < that.checkarray.length; i++) {
          if (that.checkarray != null) {
            upqrarray.push(that.checkarray[i]);
          }
        }
        return upqrarray;
      }

      /**
       * 数据更新（新页数）
       */
      function addpage() {
        if (oldarraylength < that.allmsg.length) {
          that.checkarray.length += 20; //数组长度增加
        }
      }

      /**
       * 全选
       * 取消全选
       */
      function allorno() {
        if (that.checkmsg == "全选") {
          that.checkarray.length = 0;
          that.selectlength = 0;
        }
      }

      /**
       * 判断是否是全选状态
       */
      function isallselectmeth() {
        if (that.selectlength == that.allmsg.length) {
          that.checkmsg = "取消全选";
        } else {
          that.checkmsg = "全选";
        }
      }

      /**
       * 删除数据
       */
      function deleteqr() {
        that.$toast.loading();
        that.$http
          .post("/firm/v1/Background/delQrCode", {
            qr_ids: upqr(),
            reqType: "Background",
          })
          .then((res) => {
            that.selectallmsg();
            that.selectlength = 0;
            that.checkarray.length = 0;
          });
      }

      /**
       * 全选
       */
      function selectalldiv() {
        allorno();

        for (let i = 0; i < that.allmsg.length; i++) {
          selectdiv(i, that.allmsg[i]);
        }
      }

      return { selectdiv, upqr, deleteqr, selectalldiv };
    },

    //创建二维码
    createqrcode() {
      this.$router.push({
        path: "backtotwo",
        query: {
          iscreate: true,
        },
      });
    },
    // 下载二维码
    download(item) {
      this.$router.push({
        path: "qrcode",
        query: {
          qrId: window.btoa(item.id),
          company: item.company,
          qrName: item.qr_name,
        },
      });
    },
    onSelect(item) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      this.show = false;

      this.sename = item.name;
      this.modifyStatus(this.qrId, item.type, this.qrindex);
    },
    shbumeth(msg, index) {
      this.qrId = msg.id; //二维码id
      this.qrindex = index; // 二维码下标
      this.show = true; //是否显示状态修改
    },

    /**
     * 修改二维码状态
     * qrId: 二维码id
     * type: 修改成的状态
     * index: 修改文件的下标
     */
    modifyStatus(qrId, type, index) {
      this.$http
        .post("/firm/v1/Background/setStatus", {
          reqType: "Background",
          qr_id: qrId,
          type: type,
        })
        .then((res) => {
          this.$toast("已" + this.sename); //动态修改提示数据

          this.allmsg[index].status = type;
        });
    },
  },
};
</script>
<style scoped>
.sede {
  display: flex;
  align-items: center;
}
.biduclass {
  margin: 0.2rem 0.3rem;
}
.biduclass img {
  width: 100%;
  height: 100%;
}
.pc {
  background-color: #f5f7fa;
}
.deleteqr {
  color: #f65a16;
  z-index: 999;
  top: 0.26rem;
  right: 0.37rem;
  font-size: 0.3rem;
  font-weight: bold;
  margin-left: 0.2rem;
}
.deleteqr2 {
  color: #f65a16;
  z-index: 999;
  font-size: 0.34rem;
  font-weight: bold;
  margin-left: 0.2rem;
}
#selength {
  bottom: 1.1rem;
}
#selength p {
  color: #f65a16;
}
.check {
  position: absolute;
  width: 0.29rem;
  height: 0.29rem;
  border-radius: 50%;
  top: 0.23rem;
  border: 1px solid #f65a16;
  display: flex;
}
.check > div {
  width: 0.2rem;
  height: 0.2rem;
  border-radius: 50%;
  background-color: #f65a16;
  margin: auto;
}
.beiall {
  margin: 0.2rem 0.3rem;
  background-color: #ffffff;
  padding: 0.37rem 0.3rem;
  border-radius: 0.2rem;
  position: relative;
}
.bianji {
  padding-top: 0.73rem;
}
.centerclass {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.17rem;
  margin-bottom: 0.38rem;
}
.centerclass>#peoclass{
  padding: .05rem 0;
}

.beiall div:first-child {
  font-size: 0.34rem;
  color: #141f33;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
}

.beiall div:nth-child(2) p {
  font-size: 0.24rem;
}
.beiall div:nth-child(2) p:first-child {
  color: #088ffa;
  font-weight: 500;
  margin-right: 0.2rem;
}
.beiall div:nth-child(2) p:nth-child(2) {
  color: #333333;
  font-size: 400;
}
.beiall div:nth-child(2) p:nth-child(3) {
  color: #088ffa;
  font-weight: 400;
}
.beiall div:nth-child(3) {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.beiall div:nth-child(3) div:first-child {
  font-size: 0.24rem;
}
.beiall div:nth-child(3) div:first-child p:nth-child(2) {
  color: #088ffa;
}
.beiall div:nth-child(3) div:nth-child(2) {
  margin: 0;
}
.qrbu {
  background-color: #ffffff;
  color: #f76729;
  border: 1px solid #f76729;
  margin: 0;
  margin-right: 0.2rem;
  padding: 0.13rem 0.25rem;
  border-radius: 0.1rem;
}
.shbu {
  background: rgba(247, 103, 41, 0.2);
  color: #f76729;
  border: none;
  margin: 0;
  padding: 0.14rem 0.35rem;
  border-radius: 0.1rem;
}
.bodi {
  height: 2.5rem;
}
.load {
  text-align: center;
  margin-top: 2rem;
}
.botqr {
  background-color: #ffffff;
  position: fixed;
  width: 88%;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4% 6%;
}
.botqr {
  color: #a1a3a7;
  font-size: 0.3rem;
  line-height: 0.34rem;
}
.botqr > div > span {
  color: #f76729;
  font-size: 0.3rem;
  line-height: 0.34rem;
}
#adqr {
  background: rgba(247, 103, 41, 0.2);
  color: #f76729;
  border: none;
  padding: 0.13rem 0.19rem;
  font-size: 0.24rem;
  line-height: 0.25rem;
  border-radius: 0.1rem;
}
.beiall .qrname {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
#qrnameid {
  color: #333333;
}
.namo>:first-child{
  white-space: nowrap;
  width: 4rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
#qrnameid{
  white-space: nowrap;
  width: 2.5rem;
  overflow: hidden;
  text-align: right;
  text-overflow: ellipsis;
}
</style>